// Generated by Framer (b0998f3)

import { addFonts, cx, CycleVariantState, SVG, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const serializationHash = "framer-Pjtpu"

const variantClassNames = {TpGJvm0MF: "framer-v-1fjuwgy"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "TpGJvm0MF", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1fjuwgy", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"TpGJvm0MF"} ref={ref ?? ref1} style={{...style}}><SVG className={"framer-x9sjvl"} data-framer-name={"Spotify"} fill={"var(--token-1b444440-6e98-401c-93cc-651f6c08f890, rgb(20, 20, 31)) /* {\"name\":\"primary-black\"} */"} intrinsicHeight={24} intrinsicWidth={24} layoutDependency={layoutDependency} layoutId={"R_7QED90H"} svg={"<svg width=\"24\" height=\"24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"M19.098 10.638C15.23 8.341 8.85 8.13 5.158 9.251a1.122 1.122 0 1 1-.652-2.148C8.745 5.816 15.79 6.064 20.244 8.708a1.122 1.122 0 0 1-1.146 1.93m-.126 3.403a.936.936 0 0 1-1.287.308c-3.225-1.982-8.143-2.557-11.958-1.399a.937.937 0 0 1-1.097-1.335.937.937 0 0 1 .554-.455c4.358-1.322 9.776-.682 13.48 1.594.44.271.578.847.308 1.287Zm-1.469 3.267a.747.747 0 0 1-1.028.249c-2.818-1.722-6.365-2.111-10.542-1.157a.748.748 0 1 1-.333-1.458c4.571-1.045 8.492-.595 11.655 1.338a.748.748 0 0 1 .248 1.028M12 0C5.373 0 0 5.372 0 12s5.373 12 12 12 12-5.372 12-12c0-6.627-5.372-12-12-12\" fill=\"#14141F\"/></svg>"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-Pjtpu.framer-bzh434, .framer-Pjtpu .framer-bzh434 { display: block; }", ".framer-Pjtpu.framer-1fjuwgy { height: 24px; overflow: hidden; position: relative; width: 24px; }", ".framer-Pjtpu .framer-x9sjvl { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 24px); left: 0px; position: absolute; right: 0px; top: 0px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 24
 * @framerIntrinsicWidth 24
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramertJ7Ox5Hj_: React.ComponentType<Props> = withCSS(Component, css, "framer-Pjtpu") as typeof Component;
export default FramertJ7Ox5Hj_;

FramertJ7Ox5Hj_.displayName = "ic-24px-spotify";

FramertJ7Ox5Hj_.defaultProps = {height: 24, width: 24};

addFonts(FramertJ7Ox5Hj_, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})